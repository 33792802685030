<template>
  <div id="nav">
    <header class="header-global">
      <div class="row">
        <div class="col-md-6 col-sm-12 logo-container">
          <router-link to="/Dashboard">
            <img class="logo" src="@/assets/img/brand/logo.svg" alt="trbo.io logo" />
          </router-link>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="nav-menu-items">
            <router-link to="/Dashboard">
              <i class="fa fa-home fa-fw"></i>
            </router-link>
            |
            <router-link to="/Settings">Settings</router-link>
            |
            <router-link to="/Password">Change Password</router-link>
            |
            <router-link to="/logout">Logout</router-link>
          </div>
        </div>
      </div>
    </header>
  </div>
  <!-- banner for Email Verification -->
  <div class="container mt-0 verification-banner"
       v-if="!hide_verification_bar && user != null && user.email_verified === false">
    <div class="row">
      <div class="col-12">
        <span v-if="!verificationEmailSent">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;
          Your email has not been verified as yet.
        </span>
        <span v-if="verificationEmailSent">
          <i class="fad fa-envelope-open-text"></i>&nbsp;
          I just sent you an email with instructions on how to verify your account!
          (Check your spam)
        </span>
        <button
            v-if="!verificationEmailSent"
            :disabled="sendingVerificationEmail || verificationEmailSent"
            @click="sendVerificationEmail()"
            class="btn btn-primary btn-sm">
          <span v-if="!sendingVerificationEmail">
            Send Verification Email
          </span>
          <span v-if="sendingVerificationEmail">
            <i class="fas fa-spin fa-spinner-third"></i>&nbsp; Sending email...
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import userInfo from '@/app/user-info';
import auth from '@/app/auth';

export default {
  props: ['hide_verification_bar'],
  data() {
    return {
      user: null,
      sendingVerificationEmail: false,
      verificationEmailSent: false,
    };
  },
  async mounted() {
    if (this.hide_verification_bar == null || !this.hide_verification_bar) {
      this.user = await userInfo.fetchUser(await auth.getUserIdToken());
    }
  },
  methods: {
    async sendVerificationEmail() {
      this.sendingVerificationEmail = true;
      await userInfo.sendVerificationEmail(await auth.getUserIdToken());
      this.sendingVerificationEmail = false;
      this.verificationEmailSent = true;
    },
  },
};
</script>
